import classnames from 'classnames';

import NoImagePlaceholderIcon from 'assets/icons/ic-image-thumbnail.inline.svg';

import styles from './NoImagePlaceholder.module.scss';

type NoImagePlaceholderProps = {
  className?: string;
  height?: number | string;
  isSmall?: boolean;
};

const NoImagePlaceholder = ({
  className,
  height,
  isSmall,
}: NoImagePlaceholderProps) => {
  const classNames = classnames(styles.root, className, {
    [styles.isSmall]: isSmall,
  });

  const textClassNames = classnames(styles.text, {
    [styles.isSmallText]: isSmall,
  });
  return (
    <div className={classNames} style={{ height: height || '100%' }}>
      <NoImagePlaceholderIcon className={styles.icon} />
      <div className={textClassNames}>Photos Coming Soon</div>
    </div>
  );
};

export default NoImagePlaceholder;
