import { pageRoutes } from 'lib/routes';

import { ProductEventData } from 'types/segment';

import { ProductCardData } from '../types';

// Handles getting necessary tracking information from product and variant
export const getProductCardTrackingInfo = (
  productCardData: ProductCardData
): ProductEventData => {
  const {
    brand,
    brandSlug,
    family,
    familySlug,
    imageUrl,
    isPromoted,
    position,
    price,
    productSid,
    productSlug,
    shoppingStatus,
    sku,
    title,
  } = productCardData;

  const displayUrl = pageRoutes.productDetail.displayUrl({
    brandSlug,
    familySlug,
    productSid,
    productSlug,
  });

  return {
    brand,
    category: family,
    image_url: imageUrl,
    is_promoted: isPromoted,
    name: title,
    position,
    price,
    product_id: productSid,
    quantity: 1,
    shoppingStatus,
    sku,
    url: displayUrl.pathname,
  };
};
