import { MouseEvent, ReactElement, useCallback } from 'react';

import { getGifUrlFromVideoAsset } from 'components/ProductGrid/ProductCard/ProductCardThumbnail/utils';

import { AlgoliaExplorerAsset } from 'lib/algolia/types';

import {
  ProductCardData,
  ProductThumbnailClickHandler,
} from 'components/ProductGrid/ProductCard/types';
import { AssetType } from 'types/generated/api';

import styles from './ProductCardThumbnail.module.scss';

export type ProductCardThumbnailProps = {
  explorer?: {
    topPostAsset: AlgoliaExplorerAsset;
    topPostId: string;
  };
  onProductThumbnailClick?: ProductThumbnailClickHandler;
  productCardData: ProductCardData;
};

export const ProductCardThumbnail = ({
  explorer,
  onProductThumbnailClick,
  productCardData,
}: ProductCardThumbnailProps): ReactElement | null => {
  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.preventDefault();

      onProductThumbnailClick?.(
        productCardData.productSid,
        explorer?.topPostId
      );
    },
    [onProductThumbnailClick, productCardData.productSid, explorer?.topPostId]
  );

  const asset = explorer?.topPostAsset;
  if (!asset || asset.type !== AssetType.VIDEO) {
    return null;
  }

  const gifAsset = getGifUrlFromVideoAsset(asset);
  if (!gifAsset) {
    return null;
  }

  return (
    <button className={styles.thumbnailButton} onClick={handleOnClick}>
      <img
        alt="video review preview"
        className={styles.thumbnail}
        src={gifAsset.animatedImageUrl}
      />
    </button>
  );
};
