import { RefinementProps } from 'lib/algolia/types';

import { AlgoliaFacetValueEntry } from 'types/contentful';

import { ProductCardVariant } from '../ProductCard/types';

export const PLP_PRICE_RANGES = [0, 50, 100, 150, 200, 300, 500, 1000];
const PLP_PRICE_RANGE_LAST_INDEX = PLP_PRICE_RANGES.length - 1;
const MIN_PRICE_RANGE = PLP_PRICE_RANGES[1];
const MAX_PRICE_RANGE = PLP_PRICE_RANGES[PLP_PRICE_RANGE_LAST_INDEX];

export const parsePriceRangeFacetLabel = (label: string) => {
  if (!label) {
    return '';
  }

  const [min, max] = label.split('-') || [];

  if (min === '0') {
    return `Under $${MIN_PRICE_RANGE}`;
  }

  if (!max) {
    return `Over $${MAX_PRICE_RANGE}`;
  }

  return `$${min} to $${max}`;
};

export const getPriceRangeSortOrder = () => {
  const sortedPrices: AlgoliaFacetValueEntry[] = [];
  PLP_PRICE_RANGES.map((priceRange, index) => {
    if (sortedPrices.length + 1 < PLP_PRICE_RANGES.length) {
      const nextIndex = index + 1;
      sortedPrices.push({
        order: index,
        value: `${priceRange}-${PLP_PRICE_RANGES[nextIndex]}`,
      });
    }
  });

  sortedPrices.push({
    order: sortedPrices.length,
    value: `${MAX_PRICE_RANGE}-`,
  });

  return sortedPrices;
};

export const getFilteredVariantsByPriceRange = (
  variants: ProductCardVariant[],
  priceRanges?: string[]
) => {
  if (!priceRanges || priceRanges.length <= 0) {
    return variants;
  }

  const filteredVariants: ProductCardVariant[] = [];

  variants.map(variant => {
    priceRanges.forEach(priceRange => {
      const [min, max] = priceRange.split('-').map(price => +price);
      if (!max && min === MAX_PRICE_RANGE && variant.price >= MAX_PRICE_RANGE) {
        filteredVariants.push(variant);
      }

      if (variant.price >= min && variant.price <= max) {
        filteredVariants.push(variant);
      }
    });
  });

  return filteredVariants;
};

export const decoratePriceRangeRefinementItems = (items: RefinementProps[]) => {
  const decoratedItems: RefinementProps[] = [];
  const priceRanges = getPriceRangeSortOrder();

  priceRanges.map(priceRange => {
    const matchingRefinementItem = items.find(
      item => item.label === priceRange.value
    );

    if (matchingRefinementItem) {
      decoratedItems.push(matchingRefinementItem);
    } else {
      const disabledItem = {
        count: 0,
        isRefined: false,
        label: priceRange.value,
      };
      decoratedItems.push(disabledItem as RefinementProps);
    }
  });

  return decoratedItems;
};
