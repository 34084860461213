import { ReactElement, useMemo } from 'react';

import { SelectedOption } from 'data/graphql/types';

import styles from './ColorSelectorHeader.module.scss';

type ColorSelectorHeaderProps = {
  isGiftCard?: boolean;
  selectedColor?: SelectedOption;
};

const ColorSelectorHeader = ({
  isGiftCard,
  selectedColor,
}: ColorSelectorHeaderProps): ReactElement | null => {
  const headerText = useMemo(
    () =>
      isGiftCard ? 'Choose a design' : `Color - ${selectedColor?.label || ''}`,
    [isGiftCard, selectedColor?.label]
  );

  return <div className={styles.header}>{headerText}</div>;
};

export default ColorSelectorHeader;
