import isNumber from 'lodash/isNumber';
import { ReactElement } from 'react';

import PriceRangeInfo from 'components/PriceRangeInfo/PriceRangeInfo';
import StarRating from 'components/Reviews/StarRating';
import { VIPBrandBadge } from 'components/VIPBrandBadge/VIPBrandBadge';

import { ProductLink } from 'lib/links';
import { ProductLinkProps } from 'lib/links/_productLink';

import styles from './ProductCardDetails.module.scss';

import { ProductPriceRanges, ProductVariantPrices } from '../types';

type ProductCardDetailsProps = {
  averageRating?: number;
  brand: string;
  currencyCode: string;
  handleTrackingProductOnClick: () => void;
  hasSelectedColor: boolean;
  isVipBrand: boolean;
  productLinkProps: Omit<ProductLinkProps, 'children'>;
  productPriceRanges: ProductPriceRanges;
  reviewCount?: number;
  selectedVariantPrices: ProductVariantPrices;
  title: string;
};

export const toCompactForm = (count: number): string => {
  if (count >= 1_000_000) {
    return `${(count / 1_000_000).toFixed(1)}M`;
  }
  if (count >= 1_000) {
    return `${(count / 1_000).toFixed(1)}K`;
  }
  return count.toString();
};

const ProductCardDetails = ({
  averageRating,
  brand,
  currencyCode,
  handleTrackingProductOnClick,
  hasSelectedColor,
  isVipBrand,
  productLinkProps,
  productPriceRanges,
  reviewCount,
  selectedVariantPrices,
  title,
}: ProductCardDetailsProps): ReactElement => {
  return (
    <div>
      <div>
        <ProductLink {...productLinkProps}>
          <a
            className={styles.anchor}
            href="placeholder"
            onClick={handleTrackingProductOnClick}
          >
            <div className={styles.brandContainer}>
              {brand}
              {isVipBrand && <VIPBrandBadge />}
            </div>
            <div className={styles.productName}>{title}</div>
            <PriceRangeInfo
              currencyCode={currencyCode}
              hasSelectedColorOption={hasSelectedColor}
              productPriceRanges={productPriceRanges}
              selectedVariantPrices={selectedVariantPrices}
            />
            {isNumber(averageRating) && (
              <StarRating
                numStars={averageRating}
                optionalClassOverride={styles.productCardStarContainer}
              >
                {reviewCount && (
                  <span className={styles.reviewCount}>
                    {toCompactForm(reviewCount)}
                  </span>
                )}
              </StarRating>
            )}
          </a>
        </ProductLink>
      </div>
    </div>
  );
};

export default ProductCardDetails;
