import classnames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';

import ColorSelectorHeader from './ColorSelectorHeader/ColorSelectorHeader';
import ColorSelectorOption from './ColorSelectorOption/ColorSelectorOption';
import { Hidden, Visible } from 'components/Grid';

import { ColorProps } from 'data/graphql/types';
import { SelectedDimensionOption } from 'types/app';

import styles from './ColorSelector.module.scss';

export enum ColorSelectorOptionType {
  BORDER = 'border',
  CHECKMARK = 'checkmark',
}

type ColorSelectorProps = {
  className?: string;
  classNameColorOption?: string;
  classNameColorOptionContainer?: string;
  colorSelectorOptionType?: ColorSelectorOptionType;
  colors?: ColorProps[];
  isGiftCard?: boolean;
  maxColorsToShow?: number;
  onClick: (selectedDimensionOption: SelectedDimensionOption) => void;
  optionName: string;
  selectedColor?: SelectedDimensionOption;
  showColorCount?: boolean;
  showColorHeader?: boolean;
};

const ColorSelector = ({
  className,
  classNameColorOption,
  classNameColorOptionContainer,
  colors = [],
  colorSelectorOptionType = ColorSelectorOptionType.CHECKMARK,
  isGiftCard,
  maxColorsToShow = Number.MAX_SAFE_INTEGER,
  onClick,
  optionName,
  selectedColor,
  showColorCount,
  showColorHeader,
}: ColorSelectorProps) => {
  if (isEmpty(colors)) {
    return null;
  }

  const numColors = colors.length;
  const colorsToDisplay =
    numColors > maxColorsToShow ? colors.slice(0, maxColorsToShow) : colors;

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.colorItems}>
        {colorsToDisplay.map(color => (
          <ColorSelectorOption
            classNameColorOption={classNameColorOption}
            classNameColorOptionContainer={classNameColorOptionContainer}
            color={color}
            isSelected={get(selectedColor, 'value') === color.value}
            key={`${color.value}-${color.swatchColorCode}`}
            onClick={onClick}
            optionName={optionName}
            optionType={colorSelectorOptionType}
          />
        ))}

        {showColorCount && (
          <div className={styles.colorCount}>
            {numColors > maxColorsToShow ? `${maxColorsToShow}+` : numColors}{' '}
            {numColors <= maxColorsToShow && pluralize('color', numColors)}
          </div>
        )}
        {showColorHeader && (
          <div className={styles.colorHeaderContainer}>
            <Hidden xs>
              <ColorSelectorHeader
                isGiftCard={isGiftCard}
                selectedColor={selectedColor}
              />
            </Hidden>
            <Visible xs>
              <ColorSelectorHeader selectedColor={selectedColor} />
            </Visible>
          </div>
        )}
      </div>
    </div>
  );
};

export default ColorSelector;
