import isEmpty from 'lodash/isEmpty';

import ColorSelector, {
  ColorSelectorOptionType,
} from 'components/InputFields/ColorSelector/ColorSelector';

import { SelectedDimensionOption } from 'types/app';

import { ColorDimensionOption } from '../types';

type ProductCardColorsProps = {
  className?: string;
  colors: ColorDimensionOption[];
  handleOptionChange: (
    selectedDimensionOption: SelectedDimensionOption
  ) => void;
  optionName: string;
  selectedColor?: ColorDimensionOption;
};

const ProductCardColors = ({
  className,
  colors,
  handleOptionChange,
  optionName,
  selectedColor,
}: ProductCardColorsProps) => {
  if (!colors || isEmpty(colors) || colors.length === 1) {
    return null;
  }

  return (
    <ColorSelector
      className={className}
      colorSelectorOptionType={ColorSelectorOptionType.BORDER}
      colors={colors}
      maxColorsToShow={5}
      onClick={handleOptionChange}
      optionName={optionName}
      selectedColor={selectedColor}
      showColorCount
    />
  );
};

export default ProductCardColors;
