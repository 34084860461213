import find from 'lodash/find';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import max from 'lodash/max';
import min from 'lodash/min';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import size from 'lodash/size';
import toPairs from 'lodash/toPairs';
import { SearchState } from 'react-instantsearch-core';

import { ProductLinkProps } from 'lib/links/_productLink';
import Logger from 'lib/utils/Logger';
import { encodeColorAttributeValueForURL } from 'lib/utils/product/pageUtils';

import { ProductOptionTypeEnum } from 'data/graphql/types';
import { OptionProps, ProductQueryStringParams } from 'types/app';

import { AlgoliaSortOptionEnum } from '../../../../lib/algolia/utils';
import { getFilteredVariantsByPriceRange } from '../../RefinementList/priceRangeUtils';
import {
  ProductCardData,
  ProductCardDimension,
  ProductCardDimensionColor,
  ProductCardDimensionOption,
  ProductCardDimensionString,
  ProductCardVariant,
  ProductCardVariantDimensionOption,
  ProductPriceRanges,
} from '../types';

const pickNameAndValue = (optionValue: ProductCardVariantDimensionOption) =>
  pick(optionValue, ['name', 'value']);

const getFullPlpOption = (
  plpOptions: ProductCardDimension[],
  selectedDimensionOption: ProductCardVariantDimensionOption
): ProductCardDimension | undefined => {
  const plpOption = find(
    plpOptions,
    p => p.name === selectedDimensionOption.name
  );
  return plpOption;
};

// Needs investigation: I'm not sure what is function is doing.
const getDimensionOption = (
  dimensions: ProductCardDimension[],
  selectedDimensionOption: ProductCardVariantDimensionOption
): ProductCardDimensionOption | undefined => {
  const dimensionOptions = get(
    find(dimensions, p => p.name === selectedDimensionOption.name),
    'values'
  );
  return find(
    dimensionOptions,
    plpOptionValue => plpOptionValue.value === selectedDimensionOption.value
  );
};

// given selectedOptions return an entire optionValue entry
const findVariantBySelectedDimensionOptions = (
  variants: ProductCardVariant[],
  selectedDimensionOptions: ProductCardVariantDimensionOption[]
): ProductCardVariant | undefined => {
  const numDimensions = size(get(variants, [0, 'values']));
  // TODO: NEXTJS12 - Not related to nextjs upgrade, but this throws an error when color is primary dimension.
  if (numDimensions !== selectedDimensionOptions.length) {
    Logger.error(
      `${numDimensions} options needed to find optionValue, you passed ${selectedDimensionOptions.length}`
    );
    return undefined;
  }

  const lookForOptions = selectedDimensionOptions
    .map(pickNameAndValue)
    .map(toPairs);
  return find(variants, variant => {
    const currentOptions = (variant?.values ?? [])
      .map(pickNameAndValue)
      .map(toPairs);
    return isEqual(currentOptions, lookForOptions);
  });
};

const formatVariantForCartModal = (
  plpOptions: ProductCardDimension[],
  selectedDimensionOptions: ProductCardVariantDimensionOption[]
): OptionProps[] => {
  return selectedDimensionOptions.map(selectedDimensionOption => {
    const label = get(
      getFullPlpOption(plpOptions, selectedDimensionOption),
      'label'
    )!;
    const value = get(
      getDimensionOption(plpOptions, selectedDimensionOption),
      'label'
    )!;
    return {
      key: label,
      label,
      value,
    };
  });
};

const getProductV3LinkParams = (
  productLinkProps: ProductLinkProps,
  variantSid?: string,
  currentSelectedDimensionOptions?: ProductCardVariantDimensionOption[],
  colorDimension?: ProductCardDimension
) => {
  const updatedProductLinkProps: ProductLinkProps = { ...productLinkProps };
  const queryStringParams: ProductQueryStringParams = {};
  updatedProductLinkProps.queryStringParams = queryStringParams; // resets potentially changed data

  if (variantSid) {
    queryStringParams.variant_id = variantSid;
  } else if (!!currentSelectedDimensionOptions && !!colorDimension) {
    const colorOptions = get(colorDimension, 'values');

    // Even if only one color option on PLP, there might be multiple on PDP
    if (!isEmpty(colorOptions)) {
      let currentColorOption;
      currentSelectedDimensionOptions.forEach(option => {
        if (get(option, 'name') === get(colorDimension, 'name')) {
          currentColorOption = option;
        }
      });

      if (currentColorOption) {
        queryStringParams.color = get(currentColorOption, 'value');
      }
    }
  }

  return updatedProductLinkProps;
};

const getProductV4LinkParams = (
  productLinkProps: ProductLinkProps,
  currentSelectedDimensionOptions?: ProductCardVariantDimensionOption[],
  colorDimension?: ProductCardDimension
): ProductLinkProps => {
  const updatedProductLinkProps: ProductLinkProps = {
    ...productLinkProps,
  };
  const queryStringParams: ProductQueryStringParams = {};
  updatedProductLinkProps.queryStringParams = queryStringParams; // resets potentially changed data

  if (!!currentSelectedDimensionOptions && !!colorDimension) {
    const colorOptions = get(colorDimension, 'values');

    // Even if only one color option on PLP, there might be multiple on PDP
    if (!isEmpty(colorOptions)) {
      let currentColorOption;
      currentSelectedDimensionOptions.forEach(option => {
        if (get(option, 'name') === get(colorDimension, 'name')) {
          currentColorOption = option;
        }
      });

      if (currentColorOption) {
        const colorParam = get(currentColorOption, 'value', '').toLowerCase();
        queryStringParams.color = encodeColorAttributeValueForURL(colorParam);
      }
    }
  }

  return updatedProductLinkProps;
};

const getMinAndMaxVariantPrices = (optionValues: ProductCardVariant[]) => {
  const inStockVariantOptions = optionValues.filter(option => option.inStock);
  const variantPrices = inStockVariantOptions.length
    ? inStockVariantOptions.map(optionValue => optionValue.price)
    : optionValues.map(optionValue => optionValue.price);
  return {
    maxPrice: max(variantPrices),
    minPrice: min(variantPrices),
  };
};

const getMinAndMaxVariantCompareAtPrices = (
  optionValues: ProductCardVariant[]
) => {
  const variantCompareAtPrices = optionValues.map(
    optionValue => optionValue.compareAtPrice
  );

  return {
    maxPrice: max(variantCompareAtPrices),
    minPrice: min(variantCompareAtPrices),
  };
};

const getProductPriceRanges = (
  optionValues: ProductCardVariant[]
): ProductPriceRanges => {
  return {
    compareAtPriceRange: getMinAndMaxVariantCompareAtPrices(optionValues),
    priceRange: getMinAndMaxVariantPrices(optionValues),
  };
};

// Returns price, compareAtPrice, and currencyCode for the selected variant, otherwise gets it from default variant.
const getVariantPriceInformation = (
  selectedVariant: ProductCardVariant | undefined,
  defaultVariant: ProductCardData
) => {
  const { compareAtPrice, currencyCode } = defaultVariant;

  const price = selectedVariant?.price ?? defaultVariant.price;

  return { compareAtPrice, currencyCode, price };
};

const findColorDimension = (
  options: ProductCardDimension[]
): ProductCardDimensionColor | undefined => {
  const colorOption = options.find(
    option => option.type === ProductOptionTypeEnum.Colors
  ) as ProductCardDimensionColor | undefined;
  return colorOption;
};

const findStringDimension = (
  options: ProductCardDimension[]
): ProductCardDimensionString | undefined => {
  const stringOption = options.find(
    option => option.type === ProductOptionTypeEnum.Sizes
  ) as ProductCardDimensionString | undefined;
  return stringOption;
};

const getCardFallbackImage = (
  productCardData: ProductCardData
): string | undefined => {
  // Not all productCardData.dimensions[].values[].preview.images are populated.
  // Here we go through and find the first one we can.
  const dimensionOptionWithImage = find(
    first(productCardData.dimensions)?.values,
    value => !!value.preview.images[0]
  );
  return dimensionOptionWithImage?.preview.images[0];
};
const areOptionsMatched = (
  variantOptions: ProductCardVariantDimensionOption[],
  selectedOptions: Array<{ name: string; value: string }>
): boolean =>
  // Every selected option has a match in the provided variant option
  selectedOptions.every(selectedOption =>
    variantOptions.some(
      variantOption =>
        (variantOption.name === selectedOption.name ||
          variantOption.label === selectedOption.name) &&
        variantOption.value === selectedOption.value
    )
  );

export const selectStartingVariant = (
  variants: ProductCardVariant[],
  dimensions: ProductCardDimension[],
  sortValue?: AlgoliaSortOptionEnum,
  searchState?: SearchState,
  selectedOptions?: Array<{ name: string; value: string }>
): ProductCardVariant | undefined => {
  if (variants.length === 1) {
    // if product has only one variant, auto-select it.
    return first(variants);
  }
  if (!isNil(selectedOptions) && !isEmpty(selectedOptions)) {
    const matchingVariant = variants.find(
      variant =>
        variant.values && areOptionsMatched(variant.values, selectedOptions)
    );
    if (matchingVariant) {
      // Return the Algolia variant first, otherwise fallback to legacy
      return matchingVariant;
    }
  }

  if (dimensions.length !== 1) {
    return undefined;
  }
  // if product has only one dimension then auto-select the first variant; take into account sorts & price filters
  if (sortValue === AlgoliaSortOptionEnum.PRICE_ASC) {
    // sorted by price asc, choose least expensive variant
    const sortedVariantsLowToHigh = orderBy(variants, ['price'], ['asc']);
    return first(sortedVariantsLowToHigh);
  }
  if (sortValue === AlgoliaSortOptionEnum.PRICE_DESC) {
    // sorted by price desc, choose most expensive variant
    const sortedVariantsHighToLow = orderBy(variants, ['price'], ['desc']);
    return first(sortedVariantsHighToLow);
  }

  // if we have price filters, filter out unwanted variants
  const filteredVariants = getFilteredVariantsByPriceRange(
    variants,
    searchState?.refinementList?.priceRange
  );

  return first(filteredVariants);
};

export {
  findColorDimension,
  findStringDimension,
  findVariantBySelectedDimensionOptions,
  formatVariantForCartModal,
  getCardFallbackImage,
  getFullPlpOption,
  getDimensionOption,
  getMinAndMaxVariantPrices,
  getProductV3LinkParams,
  getProductV4LinkParams,
  getProductPriceRanges,
  getVariantPriceInformation,
};
