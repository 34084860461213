type VariantPriceInfo = {
  compareAtPrice?: number | null;
  currencyCode?: string | null;
  price?: number | null;
};

// Returns price, compareAtPrice, and currencyCode for the selected variant, otherwise gets it from default variant.
export const getVariantPriceInformation = (
  defaultVariant?: VariantPriceInfo | null,
  selectedVariant?: VariantPriceInfo | null
): VariantPriceInfo => {
  const currencyCode =
    selectedVariant?.currencyCode || defaultVariant?.currencyCode;
  const price = selectedVariant?.price || defaultVariant?.price;
  const compareAtPrice =
    selectedVariant?.compareAtPrice || defaultVariant?.compareAtPrice;

  return { compareAtPrice, currencyCode, price };
};
