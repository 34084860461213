import classnames from 'classnames';

import { ColorSelectorOptionType } from 'components/InputFields/ColorSelector/ColorSelector';

import keyPressHandler from 'lib/ui/keyPress/keyPressHandler';

import { ColorProps } from 'data/graphql/types';
import { SelectedDimensionOption } from 'types/app';

import CheckMarkIcon from 'assets/icons/ic-checkmark.inline.svg';

import styles from './ColorSelectorOption.module.scss';

type ColorSelectorOptionProps = {
  classNameColorOption?: string;
  classNameColorOptionContainer?: string;
  color: ColorProps;
  isSelected: boolean;
  label?: string;
  onClick: (selectedDimensionOption: SelectedDimensionOption) => void;
  optionName: string;
  optionType: ColorSelectorOptionType;
};

const lightColors = new Set(['beige', 'tan', 'white']);

const isLightColor = (color: ColorProps) =>
  lightColors.has(color.brandColor.toLowerCase());

const renderSelectedStyle = (
  optionType: ColorSelectorOptionType,
  color: ColorProps,
  isSelected: boolean
) => {
  if (optionType === ColorSelectorOptionType.BORDER) {
    return (
      <div
        className={classnames(styles.innerCircle, {
          [styles.outerBoxShadow]:
            !isSelected && optionType === ColorSelectorOptionType.BORDER,
        })}
        style={{
          backgroundColor: `#${color.swatchColorCode}`,
        }}
      />
    );
  }

  return (
    <div
      className={classnames(styles.checkmark, {
        [styles.isDarkCheckmark]: isLightColor(color),
      })}
    >
      <CheckMarkIcon className={styles.checkmarkIcon} />
    </div>
  );
};

const ColorSelectorOption = ({
  classNameColorOption,
  classNameColorOptionContainer,
  color,
  isSelected,
  label,
  onClick,
  optionName,
  optionType,
}: ColorSelectorOptionProps) => {
  const style: { [key: string]: string } = {};

  if (optionType === ColorSelectorOptionType.CHECKMARK) {
    style.backgroundColor = `#${color.swatchColorCode}`;
  }

  const selectedDimensionOption: SelectedDimensionOption = {
    label: color.label,
    name: optionName,
    value: color.value,
  };

  const classNames = classnames(classNameColorOption, styles.colorOption, {
    [styles.isSelectedBorder]:
      isSelected && optionType === ColorSelectorOptionType.BORDER,
    [styles.outerBoxShadow]: optionType === ColorSelectorOptionType.CHECKMARK,
    [styles.isSelectedCheckmark]:
      isSelected && optionType === ColorSelectorOptionType.CHECKMARK,
  });

  return (
    <div
      aria-label={color.label}
      className={classnames(
        classNameColorOptionContainer,
        styles.colorOptionContainer
      )}
      onClick={() => onClick(selectedDimensionOption)}
      onKeyPress={keyPressHandler({
        Enter: () => onClick(selectedDimensionOption),
      })}
      role="button"
      tabIndex={0}
      title={color.label}
    >
      <div className={classNames} style={style}>
        {renderSelectedStyle(optionType, color, isSelected)}
      </div>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export default ColorSelectorOption;
