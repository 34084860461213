import last from 'lodash/last';

import { CLOUDINARY_VIDEO_BASE_URL } from 'components/VideoPlayer/utils';

import { AlgoliaExplorerAsset } from 'lib/algolia/types';
import Logger from 'lib/utils/Logger';

import { AssetType } from 'types/generated/api';

const CLOUDINARY_ENABLED_INPUT_PREFIX =
  'https://www.verishop.com/media-assets/';
const DEFAULT_WIDTH = 66;

export type GifAsset = {
  animatedImageUrl: string;
  imageUrl: string;
};

/**
 * Convert video asset url to animated image url. Only convert videos uploaded to cloudinary, return undefined for other videos;
 * @param asset video asset to convert
 * @param thumbnailWidth
 * @returns
 * @see https://cloudinary.com/documentation/videos_to_animated_images#delivering_animated_gifs
 */
export const getGifUrlFromVideoAsset = (
  asset: AlgoliaExplorerAsset,
  thumbnailWidth: number = DEFAULT_WIDTH
): GifAsset | undefined => {
  try {
    if (asset.type !== AssetType.VIDEO || !asset.url) {
      return;
    }

    const assetUrl = toCloudinaryUrl(asset.url);
    if (!assetUrl) {
      return;
    }

    const urlSuffix = last(assetUrl.split(CLOUDINARY_VIDEO_BASE_URL));

    const imageUrlSuffix = urlSuffix?.replace('.mp4', '.jpg');
    const imageParams = ['q_auto', `w_${thumbnailWidth}`].join(',');
    const imageUrl = `${CLOUDINARY_VIDEO_BASE_URL}/${imageParams}${imageUrlSuffix}`;

    const animatedImageUrlSuffix = urlSuffix?.replace('.mp4', '.gif');
    const boomerangParams = 'e_boomerang'; // boomerang effect
    const loopParams = 'e_loop'; // loop infinitely
    const scaleParams = ['c_scale', `w_${thumbnailWidth}`].join(','); // scale image
    const trimParams = ['eo_1', 'so_0'].join(','); // trim source video
    const animatedImageUrl = `${CLOUDINARY_VIDEO_BASE_URL}/${scaleParams}/${loopParams}/${boomerangParams}/${trimParams}${animatedImageUrlSuffix}`;

    return {
      animatedImageUrl,
      imageUrl,
    };
  } catch (error) {
    Logger.error('Failed to convert gif asset', error);
    return;
  }
};

const toCloudinaryUrl = (rawUrl: string): string | undefined => {
  // input is already cloudinary url
  if (rawUrl.startsWith(CLOUDINARY_VIDEO_BASE_URL)) {
    return rawUrl;
  }

  // input can be converted to cloudinary url
  // input will look something like this:  https://www.verishop.com/media-assets/<asset_id>.<asset_file_extension>
  // output will look something like this: https://assets3.verishop.com/<asset_type>/upload/media-assets/<asset_id>.<asset_file_extension>
  if (rawUrl.startsWith(CLOUDINARY_ENABLED_INPUT_PREFIX)) {
    const filenameAndExt = last(rawUrl.split('/')) || '';
    const [filename, ext] = filenameAndExt.split('.');
    if (filename && ext) {
      return `${CLOUDINARY_VIDEO_BASE_URL}/media-assets/${filename}.${ext}`;
    }
  }

  return;
};
